import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { Radio, Tooltip, message } from 'antd'
import { observer } from 'mobx-react'
import './index.less'

const IconFont = Loader.loadBaseComponent('IconFont')
const PlayComponent = Loader.loadBusinessComponent('PlayComponent')
const PictureCanvas = Loader.loadBusinessComponent('PictureCanvas')
const SimpleMap = Loader.loadBusinessComponent('MapComponent', 'SimpleMap')

@withRouter
@Decorator.businessProvider('tab', 'user', 'device')
@observer
class ImageMovieMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      IMMType: 0,
      PathCurrent: {},
      movieTrue: false,
      overdue: false, // 视频是否过期 false 没有过期  true已过期
      fileData: null,
      deviceList: {},
      collectStatus: false, // 图片收藏状态 false 没有收藏  true 已收藏
      data: {}
    }
    this.fileDatas = {}
    this.movieTime = null
    this.trajectRef = React.createRef()
  }

  componentWillMount() {
    const { data } = this.props
    let imgId = undefined
    if (data.imgId) {
      imgId = data.imgId
    }
    if (data.captureId) {
      imgId = data.captureId
    }
    this.getUserTime()
    clearTimeout(this.movieTime)
    this.init(data)
    this.initCollectStatus(imgId)
  }

  componentWillUnmount() {
    SocketEmitter.off(SocketEmitter.eventName.updataCollectedState, this.updateCollectStatus)
  }

  init = parms => {
    let deviceList = this.props.device.getDeviceByIdOrCid(parms.cid || parms.deviceId)
    if (!deviceList && parms.deviceId) {
      deviceList = {
        cid: parms.cid,
        latitude: parms.latitude,
        longitude: parms.longitude,
        deviceName: parms.deviceName,
        deviceStatus: '0',
        deviceType: '103501,103502'
      }
    }
    parms.ponitList = deviceList
    this.setState({
      data: parms
    })
  }

  /**
   * @desc 判断图片收藏状态
   */
  initCollectStatus = imgId => {
    if (imgId) {
      const collectStatus = !!BaseStore.mediaLib.hasCollectedKeyOfImage(imgId)
      if (collectStatus) {
        this.setState({
          collectStatus
        })
      }
      SocketEmitter.on(SocketEmitter.eventName.updataCollectedState, this.updateCollectStatus)
    } else {
      return
    }
  }

  handleCollectiton = event => {
    const { collectStatus } = this.state
    if (collectStatus) {
      this.cancelCollection()
    } else {
      this.addCollection(event)
    }
  }

  /**
   * @desc 取消收藏
   */
  cancelCollection = () => {
    const { data } = this.props
    // 根据pictureId反查id
    // let collectItem = BaseStore.mediaLib.mediaImageListLevel.find(v => data.imgId === v.pictureId || v.imgId);
    let collectItem = BaseStore.mediaLib.mediaImageListLevel.find(v => v.pictureId === data.imgId || v.pictureId === data.captureId) || {}
    BaseStore.mediaLib.deleteFavorite(collectItem)
  }

  /**
   * @desc 添加收藏
   */
  addCollection = event => {
    const { data, type } = this.props
    let pictureType = '1'
    switch (type) {
      case 'face':
        pictureType = '2'
        break
      case 'personAlarm':
        pictureType = '2'
        break
      case 'body':
        pictureType = '3'
        break
      case 'eventAlarm':
        pictureType = '3'
        break
      case 'vehicle':
        pictureType = '4'
        break
      case 'nonVehicle':
        pictureType = '4'
        break
      default:
        break
    }
    const options = {
      start: { clientX: event.clientX, clientY: event.clientY },
      url: data.sceneUrl
    }
    Utils.animateFly(options)
    let collectDeviceId = data.deviceId
    if (!collectDeviceId) {
      let cameraInfo = this.props.device.deviceArray.find(item => {
        return item.cid === data.cid
      })
      collectDeviceId = cameraInfo.id
    }
    const mediaOption = {
      pictureId: data.imgId || data.captureId,
      deviceId: collectDeviceId,
      deviceName: data.deviceName,
      captureTime: +data.captureTime,
      scenceImageUrl: data.sceneUrl,
      smallImageUrl: data.sceneUrl,
      pictureType,
      source: 2
    }
    BaseStore.mediaLib
      .addPictureFavorite(mediaOption)
      .then(() => {
        this.setState({
          collectStatus: true
        })
      })
      .catch(err => {
        message.error('收藏失败')
      })
  }

  updateCollectStatus = selectList => {
    const { data } = this.props
    const temp = selectList.find(v => v.pictureId === data.imgId || data.captureId)
    if (temp) {
      this.setState({
        collectStatus: false
      })
    }
  }

  videoImgChange = () => {
    let { imgVideoChange } = this.state
    this.setState({
      imgVideoChange: !imgVideoChange
    })
  }

  getUserTime = async () => {
    let { user, data, video } = this.props
    if (video) {
      data.videoUrl &&
        this.setState({
          movieTrue: true,
          overdue: true
        })
    } else {
      const storageLimit = await this.judgeHistory(data)
      let systemTime = user.systemTime
      if (systemTime - data.captureTime > storageLimit * 24 * 60 * 60 * 1000) {
        this.setState({
          overdue: true
        })
        return
      }
      if (systemTime - data.captureTime > 60000) {
        this.setState({
          movieTrue: true
        })
      } else {
        this.movieTime = setTimeout(() => {
          this.setState({
            movieTrue: true
          })
        }, systemTime - data.captureTime)
      }
    }
  }

  handleChangeIMM = e => {
    const type = e.target.value
    const { data, video = false } = this.props
    let { movieTrue, overdue } = this.state
    if (type === 1) {
      if (overdue) {
        message.warn('视频已过期')
        return
      }
      if (!movieTrue && !overdue) {
        return
      }
      let option = {
        cid: data.cid,
        deviceName: data.deviceName,
        startTime: parseInt(data.captureTime / 1000) - 15,
        endTime: parseInt(data.captureTime / 1000) + 15
      }
      this.getHistoryMovie(option)
    }
    this.setState({
      IMMType: type
    })
  }

  judgeHistory = parms => {
    return Service.device
      .queryDeviceInfoByCid(parms.cid)
      .then(result => {
        let storageLimit
        try {
          storageLimit = +result.data.extJson.cameraInfo.storage.video || 7
        } catch {
          storageLimit = 7
        }
        return storageLimit
      })
      .catch(e => 7)
  }
  // 获取历史视频
  getHistoryMovie = option => {
    if (this.state.fileData) {
      return
    }
    if (this.props.video) {
      const fileData = {
        file: this.props.data.videoUrl
      }
      this.setState({
        fileData
      })
      return
    }
    // 处理事件视频（4个视频段）options(30秒) option1(5分钟+30秒) option2(30秒+5分钟) option3(5分钟+30秒+5分钟)
    let options = [
      option,
      Object.assign({}, option, {
        startTime: option.startTime - 5 * 60,
        videoGroupType: 1
      }),
      Object.assign({}, option, {
        endTime: option.endTime + 5 * 60,
        videoGroupType: 2
      }),
      Object.assign({}, option, {
        startTime: option.startTime - 5 * 60,
        endTime: option.endTime + 5 * 60,
        videoGroupType: 3
      })
    ]
    Promise.all(options.map(opt => Service.video.queryHistoryAddress(opt, true, opt.videoGroupType ? false : true))).then(videoRes => {
      let res = this.props.device.getDeviceByIdOrCid(option.cid || option.deviceId)
      videoRes.forEach((item, index) => {
        this.fileDatas[index] = this.getVideoFileDatas(res, item, options[index])
      })
      this.setState({
        fileData: this.fileDatas[0]
      })
    })
  }
  /**
   * @desc 组合视频数据
   */
  getVideoFileDatas = (res, item, option) => {
    return Object.assign({}, res, {
      historyList: item,
      isLiving: false,
      timeRange: {
        startTime: option.startTime * 1000,
        endTime: option.endTime * 1000
      },
      videoGroupType: option.videoGroupType
    })
  }

  /**
   * @desc 事件视频前后5分钟处理
   */
  changeTotalVideoTime = (type, currentTime) => {
    const { videoGroupType } = this.state.fileData
    let fileData = {}
    if (type === -5 && !videoGroupType) {
      fileData = { ...this.fileDatas[1] }
    } else if (type === 5 && !videoGroupType) {
      fileData = { ...this.fileDatas[2], defaultCurrentTime: currentTime }
    } else {
      fileData = { ...this.fileDatas[3] }
      if (videoGroupType === 1) {
        fileData.defaultCurrentTime = currentTime
      }
    }
    this.setState(
      {
        fileData
      },
      () => {
        // 记录日志
        let { timeRange, cid, deviceName } = fileData
        let { startTime, endTime } = timeRange
        const beginStringDate = moment.unix(startTime / 1000).format(Shared.format.dataTime)
        const endStringDate = moment.unix(endTime / 1000).format(Shared.format.dataTime)
        const logInfo = {
          code: 103902,
          parent: 103900,
          text: '查看录像视频'
        }
        if (!deviceName) {
          let device = BaseStore.device.getDeviceByIdOrCid(cid)
          deviceName = device ? device.deviceName : this.props.data.deviceName
        }
        Service.logger.save({
          description: `查看点位【${deviceName}】 ${beginStringDate}到${endStringDate}的录像`,
          ...logInfo
        })
      }
    )
  }

  /**
   * @desc 视频截图处理
   */
  handleRectSearch = status => {
    const { fileData } = this.state
    this.setState({
      fileData: Object.assign({}, fileData, { rectSearchStatus: status })
    })
  }

  handleRectInfo = data => {
    let rects = []
    let rect = {
      default: true,
      smId: data.captureId,
      feature: data.feature || data.faceFeature || data.bodyFeature
    }
    if (data.faceRect) {
      rects.push({ type: 'face', value: data.faceRect, url: data.faceUrl, ...rect, feature: data.feature || data.faceFeature })
    }
    if (data.bodyRect) {
      rects.push({ type: 'body', value: data.bodyRect, url: data.bodyUrl, ...rect, feature: data.feature || data.bodyFeature })
    }
    if (data.vehicleRect) {
      if (data.vehicleType == '0') {
        rects.push({ type: 'vehicle', value: data.vehicleRect, url: data.vehicleUrl, ...rect })
      }
      if (data.vehicleType == '1') {
        rects.push({ type: 'nonVehicle', value: data.vehicleRect, url: data.vehicleUrl, ...rect })
      }
    }
    return rects
  }

  render() {
    let { IMMType, movieTrue, fileData, overdue, data, collectStatus } = this.state
    let { type, handleArray = ['pic', 'video', 'map'], map = true, video, searchData = {}, getAid } = this.props
    let title = video ? (!movieTrue ? (overdue ? '视频已过期' : '视频生成中，请稍后再试') : '') : overdue ? '视频已过期' : ''

    let rects = this.handleRectInfo(data)
    return (
      <div className="imm-view-box">
        <div className="switch-btn-container">
          <div className="imm-switch">
            <Radio.Group value={IMMType} onChange={this.handleChangeIMM} className="imm-switch-radio">
              {handleArray.includes('pic') && (
                <Radio.Button value={0} className="switch-box">
                  <IconFont type={'icon-S_Photo_Imge'} theme="outlined" />
                  看图片
                </Radio.Button>
              )}
              {handleArray.includes('video') && (
                <Radio.Button title={title} disabled={!(movieTrue || (video && data.videoUrl) || fileData)} value={1} className="switch-box">
                  <div title={title}>
                    <IconFont type={'icon-S_Photo_Video'} theme="outlined" />
                    看视频
                  </div>
                </Radio.Button>
              )}
              {handleArray.includes('map') && map && (
                <Radio.Button value={2} className="switch-box">
                  <IconFont type="icon-S_Photo_ListMap" theme="outlined" />
                  看地图
                </Radio.Button>
              )}
            </Radio.Group>
          </div>
        </div>
        {IMMType === 0 && type !== 'accessControl' && (
          <Tooltip title={`${collectStatus ? '取消' : ''}收藏`}>
            <IconFont className={`collection-icon ${collectStatus ? 'active' : ''}`} type="icon-S_Photo_Keep" onClick={this.handleCollectiton} />
          </Tooltip>
        )}
        <div className="imm-content">
          <div className={`imm-content-box ${IMMType === 0 && 'imm-content-box-active'} `}>
            <PictureCanvas
              imagePath={data && (data.sceneUrl || data.url || data.pictureUrl)}
              rects={rects}
              data={data}
              type={type}
              captureTime={+data.captureTime}
              searchData={searchData}
              downloadName={data.deviceName + `_${moment(+data.captureTime).format('YYYYMMDDTHHmmss')}`}
              isAlarm={data.captureId}
              beforeJumppage={this.props.beforeJumppage}
            />
          </div>
          <div className={`imm-content-box ${IMMType === 1 && 'imm-content-box-active'} `}>
            {IMMType == 1 &&
              (fileData || data.videoUrl) &&
              (type !== 'accessControl' ? (
                <PlayComponent
                  isNativeVideo={!!video}
                  isLiving={!!video}
                  hasLiving={false}
                  fileData={fileData}
                  isEventVideo={this.props.video ? false : true}
                  changeTotalVideoTime={this.changeTotalVideoTime}
                  method={{
                    downloadVideo: options => Shared.downloadVideo({ fileData, ...options }),
                    handleRectSearch: this.handleRectSearch
                  }}
                />
              ) : (
                <PlayComponent
                  hasLiving={false}
                  isNormal={true}
                  isLiving={false}
                  autoplay={false}
                  isNativeVideo={false}
                  hasScreenshot={false}
                  fileData={fileData}
                  hasDownload={false}
                />
              ))}
          </div>
          {map && (
            <div className={`imm-content-box ${IMMType === 2 && 'imm-content-box-active'} `}>
              {IMMType === 2 && (
                <SimpleMap
                  points={data.ponitList ? [data.ponitList] : []}
                  id={IMMType === 2 ? data.id : 0}
                  center={[data.longitude, data.latitude]}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ImageMovieMap
