(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("moment"), require("ReactRouterDOM"), require("mobxReact"));
	else if(typeof define === 'function' && define.amd)
		define("ImageMovieMap", ["React", "antd", "moment", "ReactRouterDOM", "mobxReact"], factory);
	else if(typeof exports === 'object')
		exports["ImageMovieMap"] = factory(require("React"), require("antd"), require("moment"), require("ReactRouterDOM"), require("mobxReact"));
	else
		root["ImageMovieMap"] = factory(root["React"], root["antd"], root["moment"], root["ReactRouterDOM"], root["mobxReact"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 